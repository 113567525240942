import React, { useState } from "react";
import axios from "axios";
import { Container, Row, Col } from "react-bootstrap";
import BlockTitle from "../block-title";

const ContactFormOne = () => {
  const [serverState, setServerState] = useState({
    submitting: false,
    status: null,
  });

  const handleServerResponse = (ok, msg, form) => {
    setServerState({
      submitting: false,
      status: { ok, msg },
    });
    if (ok) {
      form.reset();
    }
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    const form = e.target;
    setServerState({ submitting: true });
    axios({
      method: "post",
      url: "https://getform.io/f/31337cec-a7e3-465e-89d2-37e0feec6958",
      data: new FormData(form),
    })
      .then((r) => {
        handleServerResponse(true, "Thank you!", form);
      })
      .catch((r) => {
        handleServerResponse(false, r.response.data.error, form);
      });
  };

  return (
    <section className="contact-page pt-120 pb-80">
      <Container>
        <Row>
          <Col lg={5}>
            <div className="contact-page__content mb-40">
              <BlockTitle
                title={`Start volunteering\nwith our foundation.`}
                tagLine="Volunteer with Us"
              />
              <p className="block-text mb-30 pr-10">
                Get in touch and we will write back as soon as possible. Please
                let us know what volunteering activities you are interested in
                and we will do our best to match you with those opportunities.
              </p>
              <div className="footer-social black-hover">
                {/* <a href="#none" aria-label="twitter">
                  <i className="fab fa-twitter"></i>
                </a> */}
                <a
                  href="https://www.facebook.com/MYLTFatl/?ref=py_c"
                  target="_blank"
                  aria-label="facebook"
                >
                  <i className="fab fa-facebook-square"></i>
                </a>
                {/* <a href="#none" aria-label="pinterest">
                  <i className="fab fa-pinterest-p"></i>
                </a>*/}
                <a
                  href="https://www.instagram.com/myltfatl/"
                  target="_blank"
                  aria-label="instagram"
                >
                  <i className="fab fa-instagram"></i>
                </a>
              </div>
            </div>
          </Col>
          <Col lg={7}>
            <form
              className="contact-form-validated contact-page__form form-one mb-40"
              accept-charset="UTF-8"
              onSubmit={handleOnSubmit}
            >
              <div className="form-group">
                <div className="form-control">
                  <label htmlFor="name" className="sr-only">
                    Name
                  </label>
                  <input
                    type="text"
                    name="name"
                    id="name"
                    placeholder="Your Name"
                  />
                </div>
                <div className="form-control">
                  <label htmlFor="email" className="sr-only">
                    email
                  </label>
                  <input
                    type="text"
                    name="email"
                    id="email"
                    placeholder="Email Address"
                  />
                </div>
                <div className="form-control">
                  <label htmlFor="phone" className="sr-only">
                    phone
                  </label>
                  <input
                    type="text"
                    name="phone"
                    id="phone"
                    placeholder="Phone Number"
                  />
                </div>
                <div className="form-control">
                  <label htmlFor="interest" className="sr-only">
                    interest
                  </label>
                  <input
                    type="text"
                    name="interest"
                    id="interest"
                    placeholder="Volunteering Interest"
                  />
                </div>
                <div className="form-control form-control-full">
                  <label htmlFor="message" className="sr-only">
                    message
                  </label>
                  <textarea
                    name="message"
                    placeholder="Write a Message"
                    id="message"
                  ></textarea>
                </div>
                <div className="form-control form-control-full">
                  <button type="submit" className="thm-btn ">
                    {serverState.status
                      ? serverState.status.msg
                      : "Submit Message"}
                  </button>
                </div>
              </div>
            </form>
            <div className="result"></div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ContactFormOne;
